import ReactDOM from 'react-dom';
import "@patternfly/react-core/dist/styles/base.css";
import React from 'react';
import { Checkbox, SearchInput, Popover } from '@patternfly/react-core';
import { TableComposable, Thead, Tbody, Tr, Th, Td } from '@patternfly/react-table';
import Papa from 'papaparse';
import productlist from "./ProductList.csv";
import platformlist from "./PlatformList.csv"
import solutionlist from "./SolutionList.csv";
import verticallist from "./VerticalList.csv";
import typelist from "./TypeList.csv";
import { Tooltip } from '@patternfly/react-core';
import { InfoCircleIcon } from '@patternfly/react-icons';


const selectedProducts = [] as any;
const selectedPlatforms = [] as any;
const selectedSolutions = [] as any;
const selectedVerticals = [] as any;
const selectedProductTypes = [] as any;
let searchPhrase = '';

import { SelectedList } from './SelectedList';

class ControlledCheckbox extends React.Component {
  static contextType = SelectedList;
  componentDidMount() {
    const { updateProductType, updateProduct, updateSolution,updatePlatform, updateVertical } = this.context;
    
    const queryParams = new URLSearchParams(window.location.search);
    const solutions = queryParams.get("solutions");
    const products = queryParams.get("products");
    const platforms = queryParams.get("platforms");
    const verticals = queryParams.get("verticals");
    const productTypes = queryParams.get("productTypes");

    if (solutions != null) {
      for (const solution of solutions.split(",")) {
        this.handleSelectionChange(solution, selectedSolutions);
        updateSolution(solution);
      }
    }
    
    if (products != null) {
      for (const product of products.split(",")) {
        this.handleSelectionChange(product, selectedProducts);
        updateProduct(product);
      }
    }

    if (platforms != null) {
      for (const platform of platforms.split(",")) {
        this.handleSelectionChange(platform, selectedPlatforms);
        updatePlatform(platform);
      }
    }

    if (verticals != null) {
      for (const vertical of verticals.split(",")) {
        this.handleSelectionChange(vertical, selectedVerticals);
        updateVertical(vertical);
      }
    }

    if (productTypes != null) {
      for (const productType of productTypes.split(",")) {
        this.handleSelectionChange(productType, selectedProductTypes);
        updateProductType(productType);
      }
    }
  }

  handleSelectionChange = (id, container) => {
    if (!container.includes(id)) {
      container.push(id);
    } else {
      const rindex = container.indexOf(id);
      container.splice(rindex, 1);
    }
  }

  handleSolutionChange = (id) => {
    this.handleSelectionChange(id, selectedSolutions);
    this.updateURLWithFilters(); // Update URL with the selected filters
  }

  handleProductChange = (id) => {
    this.handleSelectionChange(id, selectedProducts);
    this.updateURLWithFilters(); // Update URL with the selected filters
  }

  handlePlatformChange = (id) => {
    this.handleSelectionChange(id, selectedPlatforms);
    this.updateURLWithFilters(); // Update URL with the selected filters
  }

  handleVerticalChange = (id) => {
    this.handleSelectionChange(id, selectedVerticals);
    this.updateURLWithFilters(); // Update URL with the selected filters
  }

  handleProductTypeChange = (id) => {
    this.handleSelectionChange(id, selectedProductTypes);
    this.updateURLWithFilters(); // Update URL with the selected filters
  }

  // Method to update the URL with selected filters
  updateURLWithFilters = () => {
    const urlParams = new URLSearchParams();

    // Add selected solutions to URL params
    if (selectedSolutions.length > 0) {
      urlParams.append('solutions', selectedSolutions.join(','));
    }

    // Add selected products to URL params
    if (selectedProducts.length > 0) {
      urlParams.append('products', selectedProducts.join(','));
    }

    if (selectedPlatforms.length > 0) {
      urlParams.append('platforms', selectedPlatforms.join(','));
    }

    // Add selected verticals to URL params
    if (selectedVerticals.length > 0) {
      urlParams.append('verticals', selectedVerticals.join(','));
    }

    // Add selected product types to URL params
    if (selectedProductTypes.length > 0) {
      urlParams.append('productTypes', selectedProductTypes.join(','));
    }

    // Update the browser's URL without reloading the page
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }

  productArray;
  platformArray
  solutionArray;
  verticalArray;
  typeArray;

  startload = () => {
    Papa.parse(productlist, {
      header: true,
      complete: (results) => {
        this.productArray = results.data;
      }
    });

    Papa.parse(platformlist, {
      header: true,
      complete: (results) => {
        this.platformArray = results.data;
      }
    });


    Papa.parse(solutionlist, {
      header: true,
      complete: (results) => {
        this.solutionArray = results.data;
      }
    });
    Papa.parse(verticallist, {
      header: true,
      complete: (results) => {
        this.verticalArray = results.data;
      }
    });
    Papa.parse(typelist, {
      header: true,
      complete: (results) => {
        this.typeArray = results.data;
      }
    });
  };

  emptysearch = () => {
    searchPhrase = '';
  }

  render() {
    this.startload();
    const { updateProductType, updateProduct, updateSolution, updatePlatform, updateVertical, searchAll } = this.context;
    return (
      <React.Fragment>
        <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100">
          <Thead>
            <Tr>
              <Th colSpan="2">Search</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <SearchInput
                  placeholder="Search"
                  value={searchPhrase}
                  onChange={(_event, changeContent) => { searchPhrase = changeContent; }}
                  onSearch={() => searchAll(searchPhrase)}
                  onClear={() => (this.emptysearch(), searchAll(''))}
                />
              </Td>
            </Tr>
          </Tbody>
        </TableComposable>

        {/* Solution Section */}
        <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100">
          <Thead>
            <Tr>
              <Th colSpan="2">Solution</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                {this.solutionArray.map(item =>
                  <Checkbox
                    label={item.sname}
                    aria-label={item.sname}
                    id={item.sid}
                    name={item.sid}
                    key={item.sid}
                    onChange={e => { this.handleSolutionChange(item.sid); updateSolution(item.sid); }}
                    isChecked={selectedSolutions.includes(item.sid)} // Check directly
                  />
                )}
              </Td>
            </Tr>
          </Tbody>
        </TableComposable>

                       {/* Content Type Section */}
                       <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100">
  <Thead>
    <Tr>
      <Th colSpan="2">
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 'bold' }}>
          Content Type
          <Popover
            headerContent={<div style={{ fontWeight: 'bold', fontSize: '16px', color: '#c9190b' }}>Content Type Descriptions</div>}
            bodyContent={
              <ul style={{ margin: 0, lineHeight: '1.5' }}>
                <li><strong>Solution Architecture:</strong> High-level designs based on proven customer deployments.</li>
                <li><strong>Interactive Video Demo:</strong> Step-by-step demos to simplify and explore Red Hat technologies.</li>
                <li><strong>AI Blueprint:</strong> End-to-end pipeline examples combining open source tools for building AI/ML solutions on OpenShift.</li>
                <li><strong>Solution Pattern:</strong> Modular patterns with sample apps bridging high-level designs and production-ready architectures.</li>
                <li><strong>Validated Pattern:</strong> Full, tested multi-product patterns ready for production.</li>
              </ul>
            }
            position="right"
          >
            <InfoCircleIcon style={{ cursor: 'pointer', color: '#6a6e73' }} />
          </Popover>
        </div>
      </Th>
    </Tr>
  </Thead>
  <Tbody>
    <Tr>
      <Td>
        {this.typeArray.map(item =>
          <Checkbox
            label={item.typename}
            aria-label={item.typename}
            id={item.tid}
            name={item.tid}
            key={item.tid}
            onChange={e => { this.handleProductTypeChange(item.tid); updateProductType(item.tid); }}
            isChecked={selectedProductTypes.includes(item.tid)} // Check directly
          />
        )}
      </Td>
    </Tr>
  </Tbody>
</TableComposable>





        {/* Platform Section */}
        <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100">
          <Thead>
            <Tr>
              <th colSpan="2">Platform</th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                {this.platformArray.map(item =>
                  <Checkbox
                    label={item.plname}
                    aria-label={item.plname}
                    id={item.plid}
                    key={item.plid}
                    onChange={() => { this.handlePlatformChange(item.plid); updatePlatform(item.plid); }}
                    isChecked={selectedPlatforms.includes(item.plid)}
                  />
                )}
              </Td>
            </Tr>
          </Tbody>
        </TableComposable>

 

        {/* Vertical Section */}
        <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100">
          <Thead>
            <Tr>
              <Th colSpan="2">Vertical</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                {this.verticalArray.map(item =>
                  <Checkbox
                    label={item.vname}
                    aria-label={item.vname}
                    id={item.vid}
                    name={item.vid}
                    key={item.vid}
                    onChange={e => { this.handleVerticalChange(item.vid); updateVertical(item.vid); }}
                    isChecked={selectedVerticals.includes(item.vid)} // Check directly
                  />
                )}
              </Td>
            </Tr>
          </Tbody>
        </TableComposable>

        {/* Product Section */}
        <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100">
          <Thead>
            <Tr>
              <th colSpan="2">Products</th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                {this.productArray.map(item =>
                  <Checkbox
                    label={item.pname}
                    aria-label={item.pname}
                    id={item.pid}
                    name={item.pid}
                    key={item.pid}
                    onChange={e => { this.handleProductChange(item.pid); updateProduct(item.pid); }}
                    isChecked={selectedProducts.includes(item.pid)} // Check directly
                  />
                )}
              </Td>
            </Tr>
          </Tbody>
        </TableComposable>
      </React.Fragment>
    );
  }
}

export { ControlledCheckbox, selectedProductTypes, selectedProducts,selectedPlatforms ,selectedSolutions, selectedVerticals, searchPhrase };
