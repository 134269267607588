import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
    Breadcrumb,
    BreadcrumbItem,
    Page,
    PageSection,
    SkipToContent,
    Title,
    Grid,
    GridItem
} from '@patternfly/react-core';
import detailLinks from './DetailLink.csv';
import Papa from 'papaparse';
import { Asciidoc } from '@app/ArchitectureDetail/AsciiDoc';
import { AsciidocVP } from '@app/ArchitectureDetail/AsciiDocVP';
import EditIcon from '@patternfly/react-icons/dist/esm/icons/edit-icon';
import GithubIcon from '@patternfly/react-icons/dist/esm/icons/github-icon';
import GitLabIcon from '@patternfly/react-icons/dist/esm/icons/gitlab-icon';
import MonitoringIcon from '@patternfly/react-icons/dist/esm/icons/monitoring-icon';
import DownloadIcon from '@patternfly/react-icons/dist/esm/icons/download-icon';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';

import '@app/react-asciidoc/fedora.css';
import { TableComposable, Thead, Tbody, Tr, Th, Td } from '@patternfly/react-table';
import palist from "../ArchitectureList/PAList.csv";
import productlist from "../ArchitectureList/ProductList.csv";
import platformlist from "../ArchitectureList/PlatformList.csv";

const PA_DOC_URL = "osspa/portfolio-architecture-examples/-/raw";

// Define the analytics object globally or at the top of the file
const analytics = {
    eVar111: "",
    events: "",
    tl: (linkType, linkName, eventName) => {
        console.log(`Tracking Event: ${eventName}, Title: ${analytics.eVar111}`);
        
        if (window.s) {
            window.s.linkTrackVars = "eVar111,events";
            window.s.linkTrackEvents = "event40";
            window.s.eVar111 = analytics.eVar111;
            window.s.events = analytics.events;
            window.s.tl(true, linkType, linkName); // Ensure this function is correctly configured for Adobe
        } else {
            console.warn("Adobe Analytics (window.s) is not defined!");
        }
    }
};

  

class ArchitectureDetail extends React.Component {
    detailMap = new Map();
    productUsedArray = [];
    platformUsedArray = [];
    usedproductarray = [];
    usedplatformarray = [];
    theProduct;
    additionalResourcesList = [];

    loadPA = (pageId) => Papa.parse(palist, {
        header: true,
        complete: (results) => {
            for (let i = 0; i < results.data.length; i++) {
                if (results.data[i].ppid === pageId && results.data[i].islive === "TRUE") {
                    window.location.replace("/architect/portfolio/detail/" + results.data[i].PAName);
                    return;
                } else if (results.data[i].PAName === pageId && results.data[i].islive === "TRUE") {
                    this.theProduct = results.data[i];
                    this.productUsedArray = this.theProduct.Product.split(',');
                    this.platformUsedArray = this.theProduct.Platform ? this.theProduct.Platform.split(',') : [];
                    break;
                }
            }

            if (!this.theProduct || !this.theProduct.DetailPage) {
                alert("Oops, it looks like we can't find the content you're looking for at the moment.");
                window.location.replace("/architect/portfolio");
            }

            let docname = this.theProduct.DetailPage;
            const branch = this.getBranch();
            if (this.theProduct.ProductType === "PA" || this.theProduct.ProductType === "Demo"  || this.theProduct.ProductType === "AIBlueprint" || this.theProduct.ProductType === "PA,VP" || (this.theProduct.ProductType === "SP" && !docname.includes("redhat-solution-patterns"))) {
                docname = PA_DOC_URL + "/" + branch + "/" + docname;
            }

            fetch("/architect/portfolio/" + docname, {
                headers: {
                    method: "get",
                    'Accept': 'text/asciidoc'
                }
            }).then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    alert("We apologize for the inconvenience. The requested document is currently unavailable.");
                    window.location.replace("/architect/portfolio");
                }
            }).then(data => this.setState({ data: data }, () => {
                this.updateTOCMenu();
            })).catch(error => {
                console.log("error---->" + error);
                return Promise.reject();
            });
        }
    });

    loadResources = () => Papa.parse(detailLinks, {
        header: true,
        complete: (results) => {
            for (let i = 0; i < results.data.length; i++) {
                const ppid = results.data[i].ppid;
                if (this.detailMap.has(ppid)) {
                    const newarray = this.detailMap.get(ppid);
                    newarray.push(results.data[i]);
                } else {
                    this.detailMap.set(ppid, [results.data[i]]);
                }
            }
        }
    });

    loadProductList = () => Papa.parse(productlist, {
        header: true,
        complete: (presults) => {
            for (let i = 0; i < presults.data.length; i++) {
                for (let usedIndex = 0; usedIndex < this.productUsedArray.length; usedIndex++) {
                    const productid = this.productUsedArray[usedIndex].toString();
                    if (presults.data[i].pid === productid) {
                        this.usedproductarray.push(presults.data[i]);
                    }
                }
            }
        }
    });

    loadPlatformList = () => Papa.parse(platformlist, {
        header: true,
        complete: (presults) => {
            for (let i = 0; i < presults.data.length; i++) {
                for (let usedIndex = 0; usedIndex < this.platformUsedArray.length; usedIndex++) {
                    const platformid = this.platformUsedArray[usedIndex].toString();
                    if (presults.data[i].plid === platformid) {
                        this.usedplatformarray.push(presults.data[i]);
                    }
                }
            }
        }
    });

    getBranch() {
        return window.location.hostname === "www.redhat.com" ? "production" : "main";
    }

    constructor(props) {
        super(props);
        this.state = {
            data: 'Loading....',
            isMobileView: false,
            additionalResourcesList: []
        };
    }

    iconfinder(icontype) {
        switch (icontype) {
            case "Blog": return <EditIcon />;
            case "Video": return <MonitoringIcon />;
            case "Demo": return <GithubIcon />;
            case "DemoGitlab": return <GitLabIcon />;
            case "Downloads": return <DownloadIcon />;
            default: return <InfoCircleIcon />;
        }
    }

    handleScroll = () => {
        const sections = document.querySelectorAll('section');
        const tocLinks = document.querySelectorAll('#toc-menu a');

        let index = sections.length;

        while (--index && window.scrollY + 100 < sections[index].offsetTop) { }

        tocLinks.forEach((link) => link.classList.remove('active'));
        tocLinks[index]?.classList.add('active');
    }

    updateTOCMenu = () => {
        const tocList = document.querySelector('#toc > ul');
        if (tocList) {
            const newTOCItem = document.createElement('li');
            const newTOCLink = document.createElement('a');
            const currentURL = window.location.href.split('#')[0];
            newTOCLink.href = `${currentURL}#learn-more`;
            newTOCLink.innerText = 'Learn More';
            newTOCItem.appendChild(newTOCLink);
            tocList.appendChild(newTOCItem);
        }

        if (!this.state.isMobileView) {
            const fragment = document.createDocumentFragment();
            const toc = document.getElementById("toc") ?? document.createElement("div");
            const tocMenu = document.getElementById("toc-menu");
            fragment.appendChild(toc);
            tocMenu?.appendChild(fragment);
        }
    }

    componentDidMount() {
        const pageId = window.location.pathname.replace('/architect/portfolio/detail/', '');
        this.loadPA(pageId);
        this.loadResources();
        this.loadProductList();
        this.loadPlatformList();

        window.scrollTo(0, 0);


// ✅ Arcade Event Tracking 
window.addEventListener("message", function (event) {
    // Ensure message is coming from a trusted Arcade source
    if (!["https://arcade.software", "https://demo.arcade.software"].includes(event.origin)) return;

    const arcadeEvent = event.data;

    // ✅ Log all Arcade events for debugging
    console.log("Received Arcade Event:", arcadeEvent);

    // ✅ Track "Overlay Clicked" events
    if (arcadeEvent.eventName === "Overlay Clicked") {
        analytics.eVar111 = arcadeEvent.flowName || "Unknown Arcade";
        analytics.events = "event40"; // Set event40 for Adobe Analytics
        analytics.tl("o", "Arcade Interaction", "Overlay Clicked");

        console.log("✅ Sent to Adobe Analytics:", analytics.eVar111);
    } else {
        console.log("ℹ️ Ignored event:", arcadeEvent);
    }
}, false);;
   
    }

    displayProductType(productType) {
        switch (productType) {
            case "AIBlueprint": return "AI Blueprint";
            case "SP": return "Solution Pattern";
            case "VP": return "Validated Pattern";
            case "PA,VP": return "Solution Architecture, Validated Pattern";
            case "Demo": return "Interactive Video Demos";
            case "CP": return "Community Pattern";
            default: return "Solution Architecture";
        }
    }

    displayProductTypeUrl(productType) {
        const baseUrl = `${window.location.origin}/architect/portfolio`;
        switch (productType) {
            case "AIBlueprint": return `${baseUrl}/?productTypes=AIBlueprint`;
            case "SP": return `${baseUrl}/?productTypes=SP`;
            case "VP": return `${baseUrl}/?productTypes=VP`;
            case "Demo": return `${baseUrl}/?productTypes=Demo`;
            case "PA": return `${baseUrl}/?productTypes=PA`;
            case "PA,VP": return `${baseUrl}/?productTypes=VP`;
            default: return `${baseUrl}`;
        }
    }

    render() {
        const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
            this.setState({ isMobileView: props.mobileView });
        };

        const pageId = 'main-content-page-layout-tertiary-nav';
        const PageSkipToContent = <SkipToContent href={`#${pageId}`}>Skip to content</SkipToContent>;

        const resourcelist = this.detailMap.get(this.theProduct?.ppid) || [];

        const learnMore = (
            <div>
                <h2 id="learn-more">Learn More</h2>
                {resourcelist.length > 0 && (
                    <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100">
                        <Thead>
                            <Tr>
                                <Th>Resources</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {resourcelist.map(item =>
                                <Tr key={item.url}>
                                    <Td>
                                        {this.iconfinder(item.type)}&nbsp;&nbsp;
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">{item.description}</a>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </TableComposable>
                )}

                <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100">
                    <Thead>
                        <Tr>
                            <Th>Products</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {this.usedproductarray.map(item =>
                            <Tr key={item.plink}>
                                <Td>
                                    <a href={item.plink} target="_blank" rel="noopener noreferrer">{item.pname}</a>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </TableComposable>

                {this.usedplatformarray.length > 0 && (
                    <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100">
                        <Thead>
                            <Tr>
                                <Th>Platforms</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {this.usedplatformarray.map(item =>
                                <Tr key={item.pllink}>
                                    <Td>
                                        <a href={item.pllink} target="_blank" rel="noopener noreferrer">{item.plname}</a>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </TableComposable>
                )}
            </div>
        );

        let tocGridItemSpan = 3;
        let contentGridItemSpan = 9;
        if (this.state.isMobileView === true) {
            tocGridItemSpan = 12;
            contentGridItemSpan = 12;
        }

        const ascii_render = this.theProduct?.ProductType === "VP" || this.theProduct?.ProductType === "CP" ? (
            <AsciidocVP>{this.state.data}</AsciidocVP>
        ) : (
            <Asciidoc>{this.state.data}</Asciidoc>
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.theProduct?.TitleTag}</title>
                    <meta name="description" content={this.theProduct?.metaDesc} />
                    <meta name="keywords" content={this.theProduct?.metaKeyword} />
                </Helmet>
                <Page
                    isManagedSidebar
                    skipToContent={PageSkipToContent}
                    mainContainerId={pageId}
                    onPageResize={onPageResize}
                    groupProps={{ sticky: 'top' }}
                    className="inline-page"
                >
                    <PageSection name="scrolling-section" className='main_content_section_info'>
                        <Grid>
                            <GridItem span={tocGridItemSpan} rowSpan={2}>
                                <PageSection className="tablepadding" id="toc-menu">
                                    {/* We will insert table of content here */}
                                </PageSection>
                            </GridItem>
                            <GridItem span={contentGridItemSpan} rowSpan={1}>
                                <PageSection className="banner">
                                    <Breadcrumb>
                                        <BreadcrumbItem to="#">Red Hat Architecture Center</BreadcrumbItem>
                                        <BreadcrumbItem isActive>
                                            <a href={this.displayProductTypeUrl(this.theProduct?.ProductType)}>
                                                {this.displayProductType(this.theProduct?.ProductType)}
                                            </a>
                                        </BreadcrumbItem>
                                    </Breadcrumb>

                                    <Title headingLevel="h1" id="_title_top">{this.theProduct?.Heading}</Title>
                                </PageSection>
                                <PageSection>
                                    {ascii_render}
                                </PageSection>
                            </GridItem>
                            <GridItem span={contentGridItemSpan} rowSpan={1}>
                                <PageSection>
                                    {learnMore}
                                </PageSection>
                            </GridItem>
                        </Grid>
                    </PageSection>
                </Page>
            </React.Fragment>
        );
    }
}

export { ArchitectureDetail };
